<template>
  <div
    class="request d-flex c-pointer position-relative"
    @click="goToPage"
    :class="[
      isActive ? 'active' : '',
      request.chatNewMessagesCount > 0 ? 'has-messages' : ''
    ]"
  >
    <span
      v-if="request.chatNewMessagesCount > 0"
      class="new-messages-count"
      title="Количество новых сообщениq в чате задачи"
      >{{ request.chatNewMessagesCount }}</span
    >
    <div :class="getStatus.cssClass" class="divider"></div>

    <div class="request-card pl-3">
      <div class="request-title" v-b-tooltip.focus :title="titleLong(request)">
        <!-- {{
          request.title.trim().length > 0
            ? "(" + request.id + ")" + " " + nameTask(request.title)
            : "(" + request.id + ")" + " " + nameTask(request.type.title)
            }} -->
        ({{ request.id }}) {{ request.title }}
      </div>
      <div class="request-field">
        Статус:
        <span :class="getStatus.cssClass" class="request-status status__exist">
          {{ getStatus.text }}</span
        >
      </div>

      <div v-if="device.title" class="request-field">
        Название:
        <span class="request-status status__exist">
          {{ device.title }}
        </span>
      </div>

      <div v-if="device.title" class="request-field">
        Офис:
        <span class="request-status status__exist">
          {{ office && office.name ? office.name : "Не указан" }}
        </span>
      </div>

      <div v-if="device.title" class="request-field">
        Серийный/стикер:
        <span class="request-status status__exist">
          {{ device.serialNum ? device.serialNum : "Нет данных" }} /
          {{ device.stickerNum ? device.stickerNum : "Нет данных" }}
        </span>
      </div>

      <div class="request-field">
        Создано/изменено:
        <span class="request-value">
          {{ moment(request.createdAt).format("DD.MM.YY") }} /
          {{ moment(request.updatedAt).format("DD.MM.YY") }}
        </span>
      </div>

      <div class="request-field">
        Автор:
        <span v-if="showPermission" class="request-status status__exist">
          {{ dataUserAuthor }}
        </span>
      </div>

      <div class="request-field">
        Исполнитель:
        <span v-if="showPermission" class="request-status status__exist">
          {{ dataUserExecutor ? dataUserExecutor : "Не указан" }}
        </span>
      </div>
    </div>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="new-tab-icon bi bi-arrow-up-right-circle"
      viewBox="0 0 16 16"
      @click.stop="openNewTab"
    >
      <path
        fill-rule="evenodd"
        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.854 10.803a.5.5 0 1 1-.708-.707L9.243 6H6.475a.5.5 0 1 1 0-1h3.975a.5.5 0 0 1 .5.5v3.975a.5.5 0 1 1-1 0V6.707l-4.096 4.096z"
      />
    </svg>
  </div>
</template>

<script>
import {
  CompanyApi,
  DeviceApi,
  OfficeApi,
  OrdersApi
} from "../../function/request/apiV2";

export default {
  name: "RequestListItem",
  props: {
    request: Object,
    references: Object,
    devices: Array,
    isActive: {
      type: Boolean,
      default: false,
      required: false
    },
    listParams: Object
  },

  data() {
    return {
      ordersApi: new OrdersApi(),
      officeApi: new OfficeApi(),
      deviceApi: new DeviceApi(),
      companyApi: new CompanyApi(),
      // references: [],
      dataUserExecutor: "",
      dataUserAuthor: "",
      showDeviceTitle: false,
      showPermission: false,
      device: {},
      office: []
    };
  },

  computed: {
    permission() {
      return (
        this.request.permissions[1].user.firstName +
        "" +
        this.request.permissions[1].user.lastName
      );
    },

    getStatus() {
      let status;
      let cssClass;
      if (
        this.request?.status?.title === "UNDEFINED" ||
        !this.request?.status?.title
      ) {
        status = "Задача создана";
        cssClass = "status__not-exist";
      }
      if (this.request?.status?.title === "Создан") {
        status = "Создан";
        cssClass = "yellow";
      }

      if (this.request?.status?.title === "В обработке") {
        status = "В обработке";
        cssClass = "orange";
      }

      if (this.request?.status?.title === "В работе") {
        status = "В работе";
        cssClass = "blue";
      }

      if (this.request?.status?.title === "На согласовании") {
        status = "На согласовании";
        cssClass = "purple";
      }

      if (this.request?.status?.title === "Выполнен") {
        status = "Выполнен";
        cssClass = "green";
      }

      if (this.request?.status?.title === "Закрыт") {
        status = "Закрыт";
        cssClass = "rifle-green";
      }

      return {
        text: status,
        cssClass: cssClass
      };
    }
  },
  methods: {
    responsibleCanWrite() {
      return this.permissions
        ?.filter(
          el =>
            el.roles?.includes("canWrite") || el.roles?.includes("performer")
        )
        ?.map(el => el.user);
    },

    responsibleAuthor() {
      return this.permissions
        ?.filter(el => el.roles?.includes("owner"))
        ?.map(el => el.user);
    },

    titleLong(request) {
      let title =
        request.title.trim().length > 0
          ? "(" + request.id + ")" + " " + request.title
          : "(" + request.id + ")" + " " + request.type.title;
      return title;
    },

    nameTask(name) {
      if (name.length < 21) return name;
      else {
        let name1 = name.trim();
        name1 = name1.slice(0, 20) + "...";
        return name1;
      }
    },

    goToPage() {
      this.$router.push({
        name: "Order",
        params: { id: this.request.id, listParams: this.listParams }
      });
    },

    openNewTab() {
      const routeData = this.$router.resolve({
        name: "Order",
        params: { id: this.request.id, listParams: this.listParams }
      });
      window.open(routeData.href, "_blank");
    },

    async getOffice() {
      await this.officeApi.api.getOffice(this.device.officeId).then(res => {
        this.office = res;
      });
    },

    async getDevice() {
      if (this.references.devices) {
        try {
          await this.deviceApi.api
            .getDevice(this.references.devices[0].id)
            .then(res => {
              this.device = res;
            });
        } catch (e) {
          throw Error(e);
        } finally {
          if (this.device.officeId) {
            await this.getOffice();
          }
        }
      }
    },

    async getReferences() {
      try {
        await this.ordersApi.api
          .getOrderReferences(this.request.id, ["devices"])
          .then(res => {
            this.references = res;
            if (this.references?.devices[0]?.id) {
              this.getDevice();
            }
          });
      } catch (e) {
        Error(e);
      } finally {
        this.showDeviceTitle = true;
      }
    },
    async showUsers() {
      await this.request?.permissions.forEach(value => {
        //   console.log("value",value)
        /* if (value?.roles[0] === "owner")
          this.dataUserAuthor =
            value?.user?.firstName + " " + value?.user?.lastName;
        if (value?.roles[0] === "canWrite" || value?.roles[0] === "performer")
          this.dataUserExecutor =
            value?.user?.firstName + " " + value?.user?.lastName;*/

        if (value?.roles.find(item => item === "owner")) {
          this.dataUserAuthor =
            value?.user?.firstName + " " + value?.user?.lastName;
        }

        if (
          value?.roles.find(item => item === "canWrite" || item === "performer")
        ) {
          this.dataUserExecutor =
            value?.user?.firstName + " " + value?.user?.lastName;
        }

        // console.log(value?.user);
        this.showPermission = true;
      });
    }
  },

  async mounted() {
    // console.log("listParams", this.listParams);
    // this.getReferences();
    this.showUsers();
    this.device = this.devices ? this.devices[0] : {};
  },

  watch: {
    devices(newValue) {
      this.device = newValue ? newValue[0] : {};
    }
  }
};
</script>

<style scoped lang="scss">
.request-status {
  border: none !important;
}

.request {
  border-radius: 7px;
  padding: 20px 20px 20px 0;
  background-color: var(--second-card-color);
  box-shadow: 0 2px 4px var(--block-shadow);
  position: relative;

  &-value {
    font-weight: bold;
  }

  &-title {
    font-size: 18px;
    margin-bottom: 9px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  &-field {
    font-size: 15px;
    letter-spacing: -0.3px;
  }

  .divider {
    width: 5px;
    height: 100%;
    background-color: #357af6;
    margin-left: 23px;
    border-radius: 2px;
    margin-right: 5px;
  }

  .status__not-exist {
    color: gray;
  }

  .status__exist {
    color: #f2be41;
    font-weight: bold;
  }

  .yellow {
    color: #fbbd3b;
    border: 3px solid #fbbd3b;
  }
  .blue {
    color: #2378f9;
    border: 3px solid #2378f9;
  }
  .orange {
    color: #fb823b;
    border: 3px solid #fb823b;
  }
  .purple {
    color: #7c3bfb;
    border: 3px solid #7c3bfb;
  }
  .green {
    color: #2dc44f;
    border: 3px solid #2dc44f;
  }
  .rifle-green {
    color: #116e25;
    border: 3px solid #116e25;
  }
}

.request.active {
  background-color: var(--third-card-color);
  color: var(--main-text-color-inverse);
}

.new-tab-icon {
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 16px;
}

.new-messages-count {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: 5px;
  right: 5px;
}

.has-messages {
  box-shadow: inset 0 0 0 2px #fdb814;
}
</style>
