<template>
  <tepmplate-block
    mod-class
    content-class="mytechnique-wrap"
    title-page="Задачи"
  >
    <template #titleAppend>
      <IconPlus
        @clicked="createOrder"
        class="tip-anchor"
        :class="{ 'over-top': isIntroPassed === 'false' && !orders.length }"
      />
    </template>
    <div class="w-100">
      <div
        v-if="isOrdersFiltersVisible"
        class="filters d-flex justify-content-between mt-2"
      >
        <div class="filters__column d-flex flex-column">
          <div class="task-status">
            <label title="Статус задачи"
              >Статус задачи
              <help-popover
                message="Фильтр по статусам задачи. Изначально показывает все открытые задачи"
              />
            </label>
            <b-select v-model="statusIdForSelect" @change="checkStatusId">
              <option value="opened">Все открытые</option>
              <option value="all">Все</option>
              <option value="request_is_created">Задача создана</option>
              <option value="in_progress">В обработке</option>
              <option value="in_work">В работе</option>
              <option value="is_agreement">На согласовании</option>
              <option value="is_completed">Выполнена</option>
              <option value="is_closed">Закрыт</option>
            </b-select>
          </div>
          <div class="d-flex mt-3">
            <div class="internal-external mr-2">
              <div class="mt-2">
                <b-form-checkbox
                  v-model="isInternalIncluded"
                  title="Показать только внутренние задачи"
                  >Внутренние</b-form-checkbox
                >
              </div>
              <div class="mt-2">
                <b-form-checkbox
                  v-model="isExternalIncluded"
                  title="Показать только внешние задачи"
                  >Внешние</b-form-checkbox
                >
              </div>
            </div>
            <div class="priority ml-2">
              <label title="Приоритет"
                >Приоритет
                <help-popover message="Приоритет задачи" />
              </label>
              <b-select
                v-model="priority"
                :options="orderPriorityOptions"
              ></b-select>
            </div>
          </div>
          <div
            class="tasks-action-buttons d-flex align-items-end mt-2 justify-content-start flex-grow-1"
          >
            <div class="d-flex align-items-center">
              <div class="btn btn-second add-item__btn" @click="showFind">
                Найти
              </div>
              <div class="clear ml-3" @click="clearFilter">
                Очистить
              </div>
            </div>
          </div>
        </div>

        <div class="filters__column d-flex flex-column mx-2">
          <div class="d-flex align-items-center">
            <div class="task-office flex-1 mr-1">
              <label title="Офис"
                >Офис
                <help-popover message="Офис, к которому привязан аппарат" />
              </label>
              <div class="position-relative">
                <b-input
                  type="search"
                  v-model="officeSearch"
                  @focus="showOffices = true"
                  @blur="hideOffices"
                  placeholder="Введите название офиса"
                  @input="checkedSearchOffice"
                />
                <!-- {{ sortedOfficeByInput }} -->
                <div
                  class="position-absolute optgroup offices"
                  v-if="showOffices"
                >
                  <div
                    v-for="item in sortedOfficeByInput"
                    :key="item.id"
                    class="optgroup_item"
                    @click.prevent="selectOffice(item)"
                  >
                    {{ item.companyBrand }} - {{ item.name }}
                  </div>

                  <span v-if="!sortedOfficeByInput.length">Не найдено</span>
                </div>
              </div>
            </div>
            <div class="tasks-serial-number flex-1 ml-1">
              <label title="Название, серийный/стикер номер"
                >Название, серийный/стикер номер
                <help-popover
                  message="Покажет все задачи по 1 конкретному аппарату."
                />
              </label>
              <b-input
                type="search"
                placeholder="Введите номер"
                v-model="serialNumber"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="tasks-dates mt-3 mr-1">
              <label title="Дата создания задачи от/до"
                >Дата создания задачи от/до</label
              >
              <div class="d-flex align-items-center">
                <div class="mr-1">
                  <b-input type="date" v-model="created_at_min_not_format" />
                </div>
                <div class="ml-1">
                  <b-input type="date" v-model="created_at_max_not_format" />
                </div>
              </div>
            </div>
            <div class="tasks-number-input-wrapper mt-3 ml-1">
              <label class="order-id-label" title="Перейти к задаче"
                >Переход
                <help-popover
                  message="У каждой задачи есть свой номер. Вбейте этот номер и сразу перейдите к нужной задаче"
                />
              </label>
              <b-input
                placeholder="Номер задачи"
                v-model="orderIdField"
                @keyup.enter="goToOrder"
              />
            </div>
          </div>
        </div>

        <div class="filters__column d-flex flex-column">
          <div class="hider d-flex align-items-center justify-content-end">
            <img
              class="filters-hider-icon"
              v-if="isOrdersFiltersVisible"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABG0lEQVR4nGNgGAWjYADAf0bamJvVp82Q3f+AIXvCfYbMfk3qGp43QZUhe8IzhuwJ/8E4q/8F9SzJ6lVhyJ7wBG44wpLHDLkTlSkzPL1fgSGr/yHC0AkbGbInbEFY1P+AIWuKPHmG506UYciecBfJsJ0M8fM5GOrr2Si3JG+COENW/3Ukl+9mKOzlhMuD2FkT9iIF1y2G7MlSxBme0S3GkNV/FcmFhxmypvBgqEubycWQNWE/UrzcYMiaIoHf8OzJwgzZE64QNBwGkjp5GbImHEPy6SWGzGmCDDhB1oQqJG8fZ8idyMdACKR18DNk959CclQ5bsUZEy0YsvrfMGT372PI7xcgaDgMgFwN9u2E9wzZ/ZZE6xsFo2BwAABuJZjduC14NAAAAABJRU5ErkJggg=="
              @click="hideFilters"
            />
          </div>
          <div class="tasks-users-filters">
            <div
              v-if="sortedEmployeeByInputAuthor"
              class="d-flex align-items-center my-2 pt-2"
            >
              <b-form-checkbox
                v-model="isAuthorIncluded"
                class="mr-2"
              ></b-form-checkbox>
              <b-form-input
                type="text"
                v-model="employeeSearchAuthor"
                :disabled="!isAuthorIncluded"
                list="authors-list"
                placeholder="Автор"
                @change="findAndSelectAuthor"
                autocomplete="off"
              ></b-form-input>
              <datalist id="authors-list" class="filter-datalist">
                <option :data-value="undefined">Не выбрано</option>
                <option
                  v-for="author in sortedEmployeeByInputAuthor"
                  :key="author.id"
                  :data-value="author.id"
                  @click="findAndSelectAuthor"
                  >{{ author.value }}</option
                >
              </datalist>
            </div>
            <div class="d-flex align-items-center my-2 pt-1">
              <b-form-checkbox
                v-model="isExecutorIncluded"
                class="mr-2"
              ></b-form-checkbox>
              <b-input
                type="text"
                v-model="employeeSearchExecutor"
                :disabled="!isExecutorIncluded"
                list="executors-list"
                placeholder="Исполнитель"
                @change="findAndSelectExecutor"
                autocomplete="off"
              />
              <datalist id="executors-list" class="filter-datalist">
                <option :data-value="undefined">Не выбрано</option>
                <option
                  v-for="executor in sortedEmployeeByInputExecutor"
                  :key="executor.id"
                  :data-value="executor.id"
                  @click="findAndSelectExecutor"
                  >{{ executor.value }}</option
                >
              </datalist>
            </div>
            <div class="d-flex align-items-center my-2 pt-1">
              <b-form-checkbox
                v-model="isViewerIncluded"
                class="mr-2"
              ></b-form-checkbox>
              <b-input
                type="text"
                v-model="employeeSearchViewer"
                :disabled="!isViewerIncluded"
                list="viewers-list"
                placeholder="Наблюдатель"
                @change="findAndSelectViewer"
                autocomplete="off"
              />
              <datalist id="viewers-list" class="filter-datalist">
                <option :data-value="undefined">Не выбрано</option>
                <option
                  v-for="viewer in sortedEmployeeByInputViewer"
                  :key="viewer.id"
                  :data-value="viewer.id"
                  @click="findAndSelectViewer"
                  >{{ viewer.value }}</option
                >
              </datalist>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div
              class="tasks-pages d-flex align-items-end justify-content-end flex-grow-1"
            >
              <div v-if="orders && orders.length">
                <div class="counts">
                  <span>{{ orders.length }}</span>
                  /
                  <span
                    class="pseudo-link"
                    title="Показать все задачи"
                    @click="loadAllOrders"
                    >{{ ordersCount }}</span
                  >
                </div>
              </div>
            </div>
            <div class="d-flex align-items-center form-toggler ml-5">
              <div
                id="grid-view"
                class="form-toggler__item"
                :class="{ active: listView === 'grid' }"
                @click="toggleListView('grid')"
              ></div>
              <div
                id="table-view"
                class="form-toggler__item"
                :class="{ active: listView === 'table' }"
                @click="toggleListView('table')"
              ></div>
              <div
                class="columns-visibility-settings-toggler"
                @click="isColumnsVisibilityChooserModalVisible = true"
              >
                <img
                  v-if="listView === 'table'"
                  height="21"
                  class="ml-1"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nM1VvWoVURD+NAr6BDbiP4qgYCXYaDRFHkBuEYXENMaduQloabOFrbszVzTRSgiJQpqooEHBB9BHsPKn1VJtlMjM2XvcveveezW3cGDhnDkz883/Av8NJZ1zYH0UPhkfvXHSn2B5D5IPfjbeP9OcHADLJBZ0j9/NazM883CXf6wfQbLqb+07B0H5ZbCcGc44yw2w/ADrJli+guUeSL+A9HWUsbPzZAks34Osf/eH8dyM3wLfPgqW565I+gbt/EIJYMJ54e0FEjkO0tTv7ex0P+8nXeiaHPN7a20MSX6oUX6+cxhpusPPpuOAMtUMYDkPadmIisOQyZoO6Tcs6L7+wqyL7ol5F2lzG0iugOQxWJ+BJMHVBzvjs8mGGiyikZLO+aJbPoPlbeSn6XaQPi3Cf+dfOL+qgFhNXPdPc2LGrbetFVleVgrKOlMYTH7LKxW8uRLAhIN6C/vMlEDKfd5LLMvudS95JPKkxr+e7a7MSQQwpj3WAHRlBAAyXqTok4dp4cZ0yLSnw9IS5XMuijrbmKLaKukus94iwzpI1+tF1nV/6y2yeU5ythbZwDZNZDooO9hsxXi3TW119CUbEhsWG3+b4r8dNNtLdHd/s2BbW2FV6Am/t9bGqpH0WRWUHSnqcqkZgORUsUVzcHbSI+kuu3Lh7Wx1CrIbbpz05uBlF5SzuH7J0iVLjeva6mUyQ6/raiQXMd/ZWxvEbp8bL9ZNpgZ73o+4mJNguKHPt0r+X/YeXx298S3QL1BXflHRxQAyAAAAAElFTkSuQmCC"
                  title="Настроить видимость колонок таблицы"
                />
                <div
                  v-if="listView === 'grid'"
                  class="ml-1"
                  style="width: 21px; height: 21px;"
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex align-items-center justify-content-end mt-2">
        <div class="d-flex align-items-center justify-content-between">
          <div
            class="tasks-pages d-flex align-items-end justify-content-end flex-grow-1"
          >
            <div v-if="orders && orders.length">
              <div class="counts">
                <span>{{ orders.length }}</span>
                /
                <span
                  class="pseudo-link"
                  title="Показать все задачи"
                  @click="loadAllOrders"
                  >{{ ordersCount }}</span
                >
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center form-toggler ml-5">
            <div
              id="grid-view"
              class="form-toggler__item"
              :class="{ active: listView === 'grid' }"
              @click="toggleListView('grid')"
            ></div>
            <div
              id="table-view"
              class="form-toggler__item"
              :class="{ active: listView === 'table' }"
              @click="toggleListView('table')"
            ></div>
            <div
              class="columns-visibility-settings-toggler"
              @click="isColumnsVisibilityChooserModalVisible = true"
            >
              <img
                v-if="listView === 'table'"
                height="21"
                class="ml-1"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAACPUlEQVR4nM1VvWoVURD+NAr6BDbiP4qgYCXYaDRFHkBuEYXENMaduQloabOFrbszVzTRSgiJQpqooEHBB9BHsPKn1VJtlMjM2XvcveveezW3cGDhnDkz883/Av8NJZ1zYH0UPhkfvXHSn2B5D5IPfjbeP9OcHADLJBZ0j9/NazM883CXf6wfQbLqb+07B0H5ZbCcGc44yw2w/ADrJli+guUeSL+A9HWUsbPzZAks34Osf/eH8dyM3wLfPgqW565I+gbt/EIJYMJ54e0FEjkO0tTv7ex0P+8nXeiaHPN7a20MSX6oUX6+cxhpusPPpuOAMtUMYDkPadmIisOQyZoO6Tcs6L7+wqyL7ol5F2lzG0iugOQxWJ+BJMHVBzvjs8mGGiyikZLO+aJbPoPlbeSn6XaQPi3Cf+dfOL+qgFhNXPdPc2LGrbetFVleVgrKOlMYTH7LKxW8uRLAhIN6C/vMlEDKfd5LLMvudS95JPKkxr+e7a7MSQQwpj3WAHRlBAAyXqTok4dp4cZ0yLSnw9IS5XMuijrbmKLaKukus94iwzpI1+tF1nV/6y2yeU5ythbZwDZNZDooO9hsxXi3TW119CUbEhsWG3+b4r8dNNtLdHd/s2BbW2FV6Am/t9bGqpH0WRWUHSnqcqkZgORUsUVzcHbSI+kuu3Lh7Wx1CrIbbpz05uBlF5SzuH7J0iVLjeva6mUyQ6/raiQXMd/ZWxvEbp8bL9ZNpgZ73o+4mJNguKHPt0r+X/YeXx298S3QL1BXflHRxQAyAAAAAElFTkSuQmCC"
                title="Настроить видимость колонок таблицы"
              />
              <div
                v-if="listView === 'grid'"
                class="ml-1"
                style="width: 21px; height: 21px;"
              >
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <img
          class="filters-hider-icon ml-5"
          v-if="!isOrdersFiltersVisible"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAA/UlEQVR4nO2R3UoCURSFVz/mqwRdhBKSQo/hK4TtPT5CHCFvnb1HCPpBMQgEEaK3qCfRIuz3fsJxGA8JeqiBEOa73Gettdc5B8jIWENqwSFIuvBaJWcPSxmsNzgJKqvFpI9gDcHy6WTw/COQfs08+uDSphGLQ5C8L73JrPnHXK+nqxcg3ADLeWJifQP7BwuyelAAy8TSXUVeN6ZL9MJq9gr2i8mx194HyYsV3oExm47h1hLSa+u5nkH+Hrz2LkjG1rz3i/CY6mALJP15UxmB9clqfhtp/kT155Kk+RDGbCMVji9zIL23/uQumqVKPciDtAnWMxizk254Rsa/8g0pQIzaX6HRyAAAAABJRU5ErkJggg=="
          @click="showFilters"
        />
      </div>
    </div>

    <div style="height:10px"></div>
    <div class="scroll-container">
      <b-overlay :show="spinner">
        <vuescroll @handle-scroll="handleScroll">
          <template v-if="listView === 'grid'">
            <div class="grid">
              <RequestListItem
                v-for="request in orders"
                :key="request.id"
                :request="request"
                :references="request.references"
                :device="request.device"
                :listParams="listParams"
              />
            </div>
          </template>
          <template v-if="listView === 'table'">
            <div class="table-wrapper">
              <table
                v-if="sortedColumnsArray.length"
                class="table b-table table-bordered table-striped"
                :key="columnsVisibilityKey"
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th
                      v-for="key in sortedColumnsArray"
                      :key="key"
                      v-show="columnsVisibility[key].value"
                      :class="{
                        'pseudo-link': columnsVisibility[key].sortable
                      }"
                      @click="setSorting(key)"
                    >
                      <img
                        v-if="sortType === key && sortOrder === 'ASC'"
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAQElEQVR4nGNgGAWEQBYUkwWKGBgY/kNxFSWa/5NqSBEWzUQbUoRHM0FDCojQDMMgtRiggYGBYRWRGKR2FFALAAArwCrr3qu96AAAAABJRU5ErkJggg=="
                      />
                      <img
                        v-else-if="sortType === key && sortOrder === 'DESC'"
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAaUlEQVR4nO3PoQ2DUBQF0DMAqWAKmlRgMF2CRWCqqnagCtRfgtBgICQIxC+fVnOS6+59yeO0dcUTr0SWTiEiR8CUSFi7URU+O+MRdwntzoHGQY/IePn9sAzvzbjDxY9u6DGg9Kd6zcl3M2zuK/S5fVJJAAAAAElFTkSuQmCC"
                      />
                      <img
                        v-else-if="
                          sortType !== key && columnsVisibility[key].sortable
                        "
                        class="mr-1"
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAgElEQVR4nM2P0QmAMAxEH7iFOIYjuJAiqBs4gns4jXSYSuE+pDS2xR/v55qEe0nhb2qBU57SkgtfgJenIHsuvAqwGhDzgkMhBED1UXpB83h7o/96AQagZvYjQKwB6CJAp37RlglwCnm5U59SzQoFQPBQm7L+OWq21WyO1X8JJ3UDta8hfWBA/RwAAAAASUVORK5CYII="
                      />
                      <span>{{ columnsVisibility[key].title }}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in orders" :key="item.id" class="item-row">
                    <td
                      class="pseudo-link"
                      title="Открыть задачу"
                      @click="goToRequest(item.id)"
                    >
                      {{ item.id }}
                    </td>
                    <td
                      v-for="key in sortedColumnsArray"
                      :key="key"
                      v-show="columnsVisibility[key].value"
                    >
                      <template v-if="key === 'status'">
                        <template v-if="!item.status">
                          <span>-</span>
                        </template>
                        <template v-else>
                          {{ item.status.title }}
                        </template>
                      </template>
                      <template v-else-if="key === 'desc'">
                        <template
                          v-if="!item.texts.desc || !item.texts.desc.length"
                        >
                          <span>-</span>
                        </template>
                        <template v-else>
                          {{ item.texts.desc }}
                        </template>
                      </template>
                      <template v-else-if="key === 'type'">
                        <template v-if="!item.type">-</template>
                        <template v-else>{{ item.type.title }}</template>
                      </template>
                      <template
                        v-else-if="['createdAt', 'updatedAt'].includes(key)"
                      >
                        <span>{{ moment(item[key]).format("DD.MM.YY") }}</span>
                      </template>
                      <template v-else-if="key === 'companyId'">
                        <template v-if="!item.companyId">-</template>
                        <template v-else>{{
                          getRequestCompanyName(item)
                        }}</template>
                      </template>
                      <template v-else-if="key === 'authorId'">
                        <span v-html="getRequestAuthor(item)"></span>
                      </template>
                      <template v-else-if="key === 'performerId'">
                        <span v-html="getRequestExecutor(item)"></span>
                      </template>
                      <template v-else-if="key === 'viewerId'">
                        <span v-html="getRequestViewer(item)"></span>
                      </template>
                      <template v-else>
                        <span>{{ item[key] || "-" }}</span>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
        </vuescroll>
      </b-overlay>
    </div>

    <router-link :to="{ name: 'RequestCreate', params: { typeId: 5 } }">
      <div class="order-create-btn">
        <p></p>
      </div>
    </router-link>

    <div
      v-if="isIntroPassed === 'false' && !orders.length"
      class="dark-intro-background full"
    >
      <div class="intro-tip-wrapper">
        <!-- <div class="intro-target"></div> -->
        <div class="intro-tip p-2 w-25 text-center">
          Создайте задачу
        </div>
      </div>
    </div>

    <!-- <ColumnsVisibilityChooser
      v-if="isColumnsVisibilityChooserModalVisible"
      :src="columnsVisibility"
      @change-visibility="setColumnsVisibilityValue"
      @close="hideColumnsVisibilityChooserModal"
      /> -->

    <TableViewSettings
      v-if="isColumnsVisibilityChooserModalVisible"
      :src="Object.values(columnsVisibility)"
      @close="hideColumnsVisibilityChooserModal"
      @apply="doApplyTableViewSettings"
    />
  </tepmplate-block>
</template>

<script>
import TepmplateBlock from "../../components/TepmplateBlock";
import { OrdersApi, CompanyApi } from "../../function/request/apiV2";
import vuescroll from "vuescroll";
import RequestListItem from "../../components/Request/RequestListItem";
import HelpPopover from "@/components/HelpPopover";
import RequestManager from "../../function/request/RequestManager";
import { mapGetters, mapState } from "vuex";
import IconPlus from "@/components/Form/IconPlus";
import { doPoseTip } from "@/utils";
import eventBus from "@/utils/event-bus";
import { ORDER_PRIORITY_OPTIONS } from "@/app-config";
// import ColumnsVisibilityChooser from "@/components/Modal/ColumnsVisibilityChooser.vue";
import TableViewSettings from "@/components/Modal/TableViewSettings.vue";

const DEFAULT_COLUMNS_CONFIG = {
  title: {
    title: "Название",
    value: true,
    order: 0,
    sortable: false,
    width: 0
  },
  status: { title: "Статус", value: true, order: 1, sortable: false, width: 0 },
  desc: { title: "Описание", value: true, order: 2, sortable: false, width: 0 },
  createdAt: {
    title: "Создана",
    value: true,
    order: 3,
    sortable: true,
    width: 0
  },
  updatedAt: {
    title: "Обновлена",
    value: true,
    order: 4,
    sortable: true,
    width: 0
  },
  authorId: { title: "Автор", value: true, order: 5, sortable: true, width: 0 },
  performerId: {
    title: "Исполнитель",
    value: true,
    order: 6,
    sortable: true,
    width: 0
  },
  viewerId: {
    title: "Наблюдатель",
    value: true,
    order: 7,
    sortable: true,
    width: 0
  },
  companyId: {
    title: "Компания",
    value: true,
    order: 8,
    sortable: true,
    width: 0
  },
  type: { title: "Тип", value: true, order: 9, sortable: true, width: 0 },
  priority: {
    title: "Приоритет",
    value: true,
    order: 10,
    sortable: true,
    width: 0
  },
  estimation: {
    title: "Оценка",
    value: true,
    order: 11,
    sortable: true,
    width: 0
  }
  /* deviceId: { title: "ID техники", value: true, order: 12, sortable: false, width: 0 },
  deviceName: { title: "Название техники", value: true, order: 13, sortable: false, width: 0 },
  deviceOfficeId: { title: "Офис", value: true, order: 14, sortable: false, width: 0 } */
};

const STORAGE_PREFIX = "aura_orders_";

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

export default {
  name: "OrdersListV2",
  components: {
    IconPlus,
    HelpPopover,
    RequestListItem,
    TepmplateBlock,
    vuescroll,
    // ColumnsVisibilityChooser
    TableViewSettings
  },
  data() {
    return {
      isOrdersFiltersVisible: undefined,
      firstStart: true,
      employees: [],
      //    selectedEmployee: null,
      spinner: false,
      companyApi: new CompanyApi(),
      ordersApi: new OrdersApi(),
      orders: [],
      devices: [],
      count: 0,
      offset: 0,
      limit: 20,
      currentOrder: null,
      references: null,
      ordersCount: 0,

      statusIdForSelect: "opened",
      created_at_min: undefined,
      created_at_max: undefined,
      created_at_min_not_format: undefined,
      created_at_max_not_format: undefined,
      serialNumber: "",
      officeSearch: "",
      employeeSearch: "",
      orderIdField: undefined,
      allOffices: [],
      currentUserId: undefined,
      mainCompanyInfo: undefined,

      showOffices: false,
      showEmployees: false,

      listParams: {
        limit: this.limit,
        offset: this.offset,
        statusIds: ["1", "2", "3", "5", "6"],
        createdAtMin: undefined,
        createdAtMax: undefined,
        serialNumber: "",
        office_id: undefined,
        selectedType: "Executor",
        employee_id: undefined,
        typeIds: undefined,
        companyId: undefined,
        ordersIds: undefined,
        reverseOrdersIds: undefined,
        parentOrdersIds: undefined,
        childOrdersIds: undefined,
        devicesIds: undefined,
        updatedAtMin: undefined,
        updatedAtMax: undefined
      },

      isEmployeesListLoading: false,
      isAuthorIncluded: false,
      isExecutorIncluded: false,
      isViewerIncluded: false,
      employeeSearchAuthor: "",
      employeeSearchExecutor: "",
      employeeSearchViewer: "",
      selectedAuthor: undefined,
      selectedExecutor: undefined,
      selectedViewer: undefined,
      orderPriorityOptions: [
        { text: "Не выбран", value: null },
        ...ORDER_PRIORITY_OPTIONS
      ],
      isInternalIncluded: false,
      isExternalIncluded: false,
      priority: null,

      listView: undefined,
      columnsVisibility: Object.assign({}, DEFAULT_COLUMNS_CONFIG),
      columnsVisibilityKey: 0,
      isColumnsVisibilityChooserModalVisible: false,
      sortType: "updatedAt",
      sortOrder: "DESC",
      needAppendList: false
    };
  },

  computed: {
    sortedColumnsArray() {
      return Object.keys(this.columnsVisibility);
    },

    sortedSrc() {
      return Object.values(this.columnsVisibility).sort(
        (a, b) => a.order - b.order
      );
    },
    device() {
      const firstDevice = this.references?.devices;
      return firstDevice?.title ?? "Не указано";
    },
    ...mapGetters(["officeList", "tagsList"]),
    ...mapState("devices", ["devicesTypes", "devicesStatus"]),
    sortedOfficeByInput() {
      if (this.officeSearch?.length > 0) {
        return this.allOffices
          ? this.allOffices
              .filter(el => {
                return (
                  el.name
                    .toLowerCase()
                    .includes(this.officeSearch.toLowerCase()) ||
                  el.companyBrand
                    .toLowerCase()
                    .includes(this.officeSearch.toLowerCase())
                );
              })
              .sort((a, b) => (a.name || "").localeCompare(b.name))
              .sort((a, b) =>
                (a.companyBrand || "").localeCompare(b.companyBrand)
              )
          : [];
      } else {
        return this.allOffices;
      }
    },
    sortedEmployeeByInputAuthor() {
      let newEmployees = this.employees
        .filter(el => {
          return el.value
            .toLowerCase()
            .includes(this.employeeSearchAuthor.toLowerCase());
        })
        .sort((a, b) => a.value.localeCompare(b.value));

      return newEmployees;
    },
    sortedEmployeeByInputExecutor() {
      let newEmployees = this.employees
        .filter(el => {
          return el.value
            .toLowerCase()
            .includes(this.employeeSearchExecutor.toLowerCase());
        })
        .sort((a, b) => a.value.localeCompare(b.value));

      return newEmployees;
    },
    sortedEmployeeByInputViewer() {
      let newEmployees = this.employees
        .filter(el => {
          return el.value
            .toLowerCase()
            .includes(this.employeeSearchViewer.toLowerCase());
        })
        .sort((a, b) => a.value.localeCompare(b.value));

      return newEmployees;
    },
    crmManagerId() {
      return this.$store.getters?.mainCompanyInfo?.crm_manager;
    }
  },

  methods: {
    doApplyTableViewSettings(settings) {
      const newColumnsVisibility = {};
      let idx = 0;
      settings.forEach(setting => {
        Object.keys(this.columnsVisibility).forEach(key => {
          if (this.columnsVisibility[key].title === setting.title) {
            setting.order = idx;
            newColumnsVisibility[key] = setting;
            idx++;
          }
        });
      });
      this.columnsVisibility = Object.assign(newColumnsVisibility);
      this.isColumnsVisibilityChooserModalVisible = false;
      localStorage.setItem(
        `${STORAGE_PREFIX}cols_visibility`,
        JSON.stringify(this.columnsVisibility)
      );
    },

    showFilters() {
      this.isOrdersFiltersVisible = true;
      localStorage.setItem("isOrdersFiltersVisible", "true");
    },

    hideFilters() {
      this.isOrdersFiltersVisible = false;
      localStorage.setItem("isOrdersFiltersVisible", "false");
    },

    goToRequest(requestId) {
      this.$router.push({
        name: "Order",
        params: { id: requestId }
      });
    },

    hideColumnsVisibilityChooserModal() {
      this.isColumnsVisibilityChooserModalVisible = false;
    },

    setSorting(sorting) {
      if (this.sortType === sorting) {
        this.sortOrder = this.sortOrder === "DESC" ? "ASC" : "DESC";
      }
      this.sortType = sorting;
      this.offset = 0;
      this.listParams.offset = 0;
      this.needAppendList = false;
      this.getOrdersListWithParams();
    },

    toggleListView(viewType) {
      this.listView = viewType;
      localStorage.setItem("ordersListView", viewType);
    },

    getRequestAuthor(order) {
      const author = order.permissions.find(perm =>
        perm.roles.includes("owner")
      );
      if (this.listView === "table") {
        return (
          `${author?.user?.firstName}<br />${author?.user?.lastName}` || "-"
        );
      } else {
        return `${author?.user?.firstName} ${author?.user?.lastName}` || "-";
      }
    },

    getRequestExecutor(order) {
      const executor = order.permissions.find(perm =>
        perm.roles.includes("performer")
      );
      if (this.listView === "table") {
        return (
          `${executor?.user?.firstName}<br />${executor?.user?.lastName}` || "-"
        );
      } else {
        return (
          `${executor?.user?.firstName} ${executor?.user?.lastName}` || "-"
        );
      }
    },

    getRequestViewer(order) {
      const viewer = order.permissions.find(perm =>
        perm.roles.includes("viewer")
      );
      if (!viewer) {
        return "-";
      }
      if (this.listView === "table") {
        return (
          `${viewer?.user?.firstName}<br />${viewer?.user?.lastName}` || "-"
        );
      } else {
        return `${viewer?.user?.firstName} ${viewer?.user?.lastName}` || "-";
      }
    },

    getRequestCompanyName(order) {
      const company = this.allCompanies.find(
        company => company.id === Number(order.companyId)
      );
      return company ? company.brand : "-";
    },

    getRequestDevice(order) {
      console.log(order);
      return "-";
    },

    getStatus(request) {
      let status;
      let cssClass;
      if (request?.status?.title === "UNDEFINED" || !request?.status?.title) {
        status = "Задача создана";
        cssClass = "status__not-exist";
      }
      if (request?.status?.title === "Создан") {
        status = "Создан";
        cssClass = "yellow";
      }

      if (request?.status?.title === "В обработке") {
        status = "В обработке";
        cssClass = "orange";
      }

      if (request?.status?.title === "В работе") {
        status = "В работе";
        cssClass = "blue";
      }

      if (request?.status?.title === "На согласовании") {
        status = "На согласовании";
        cssClass = "purple";
      }

      if (request?.status?.title === "Выполнен") {
        status = "Выполнен";
        cssClass = "green";
      }

      if (request?.status?.title === "Закрыт") {
        status = "Закрыт";
        cssClass = "rifle-green";
      }

      return {
        text: status,
        cssClass: cssClass
      };
    },

    doPassIntro() {
      this.isIntroPassed = "true";
      localStorage.setItem("isIntroPassed", "true");
      this.getOrdersListWithParams();
    },

    findAndSelectAuthor(selectedAuthorValue) {
      this.selectedAuthor = this.employees.find(
        empl => empl.value === selectedAuthorValue
      )?.id;
    },

    findAndSelectExecutor(selectedExecutorValue) {
      this.selectedExecutor = this.employees.find(
        empl => empl.value === selectedExecutorValue
      )?.id;
    },

    findAndSelectViewer(selectedViewerValue) {
      this.selectedViewer = this.employees.find(
        empl => empl.value === selectedViewerValue
      )?.id;
    },

    handleFocus(e) {
      e.stopPropagation();
      e.preventDefault();
      this.showEmployees = true;
      this.employeeSearch = "";
    },

    hideOffices() {
      setTimeout(() => {
        this.showOffices = false;
      }, 150);
    },

    selectOffice(item) {
      this.officeSearch = item.name;
      this.listParams.office_id = item.id;
    },

    checkedSearchOffice() {
      if (!this.officeSearch) {
        this.listParams.office_id = undefined;
      }
      if (this.listParams.office_id && !this.sortedOfficeByInput.length) {
        this.listParams.office_id = undefined;
      }
      if (
        this.sortedOfficeByInput.length === 1 &&
        this.officeSearch === this.sortedOfficeByInput[0].office_name
      ) {
        this.listParams.office_id = this.sortedOfficeByInput[0].id;
      }
    },

    hideEmployees() {
      setTimeout(() => {
        this.showEmployees = false;
      }, 150);
    },

    selectEmployee(item) {
      this.employeeSearch = item.value; // `${item.companyTitle} - ${item.lastName} ${item.firstName}`;
      this.listParams.employee_id = item.id ?? this.crmManagerId;
    },

    checkedSearchEmployee() {
      if (!this.employeeSearch) {
        this.listParams.employee_id = undefined;
      }
      if (this.listParams.employee_id && !this.sortedEmployeeByInput.length) {
        this.listParams.employee_id = undefined;
      }
      if (
        this.sortedEmployeeByInput.length === 1 &&
        this.employeeSearch === this.sortedEmployeeByInput[0].value
      ) {
        this.listParams.employee_id = this.sortedEmployeeByInput[0].id;
      }
    },

    checkStatusId() {
      if (this.statusIdForSelect === "opened") {
        this.listParams.statusIds = ["1", "2", "3", "5", "6"];
      }
      if (this.statusIdForSelect === "request_is_created") {
        this.listParams.statusIds = ["2"];
      }
      if (this.statusIdForSelect === "in_progress") {
        this.listParams.statusIds = ["1"];
      }
      if (this.statusIdForSelect === "in_work") {
        this.listParams.statusIds = ["3"];
      }
      if (this.statusIdForSelect === "is_agreement") {
        this.listParams.statusIds = ["5"];
      }

      if (this.statusIdForSelect === "is_completed") {
        this.listParams.statusIds = ["6"];
      }

      if (this.statusIdForSelect === "is_closed") {
        this.listParams.statusIds = ["4"];
      }
      if (this.statusIdForSelect === "all") {
        this.listParams.statusIds = undefined;
      }
    },

    goToOrder() {
      RequestManager()
        .getOrdersChat(this.orderIdField)
        .then(data => {
          if (data.success === false) {
            this.$bvToast.toast("Задачи с таким номером не существует", {
              title: "Ошибка",
              variant: "danger"
            });
          } else {
            this.$router.push({
              path: "/order/" + this.orderIdField
            });
          }
        });
    },

    createOrder() {
      if (!this.isCheckedMode) {
        this.$router.push({ name: "RequestCreate", params: { typeId: 5 } });
      }
    },

    handleScroll(vertical) {
      if (vertical.process === 1 && this.orders.length < this.ordersCount) {
        this.needAppendList = true;
        this.getOrdersListWithParams();
      }
    },

    // Функция для поиска задач  ( по аналогии с showMore(). Тут еще могут задаваться дополнительные параметры для getOrdersListWithParams )
    showFind() {
      this.orders = [];
      this.offset = 0;
      this.listParams.offset = 0;

      this.getOrdersListWithParams(); // Функция для поиска задач с условием фильтрации
    },
    uniqBy(arr, predicate) {
      const cb =
        typeof predicate === "function" ? predicate : o => o[predicate];

      return [
        ...arr
          .reduce((map, item) => {
            const key = item === null || item === undefined ? item : cb(item);

            map.has(key) || map.set(key, item);

            return map;
          }, new Map())
          .values()
      ];
    },

    showMore() {
      if (this.orders.length < this.ordersCount) {
        this.offset = this.orders.length;
        this.listParams.offset = this.orders.length;

        //  console.log("offset",this.offset)

        this.getOrdersListWithParams();
      }
      /* this.offset += this.limit;
       this.limit += this.offset;
       this.getOrdersList(this.offset);*/
    },

    clearFilter() {
      //window.localStorage.setItem("orders-list-params", "");
      this.orders = [];
      this.ordersCount = 0;
      this.statusIdForSelect = "opened";
      this.created_at_min = undefined;
      this.created_at_max = undefined;
      this.created_at_min_not_format = undefined;
      this.created_at_max_not_format = undefined;
      this.serialNumber = "";
      this.officeSearch = "";
      this.employeeSearch = "";
      this.orderIdField = undefined;

      this.showOffices = false;
      this.showEmployees = false;
      this.listParams.selectedType = "Executor";

      this.listParams.limit = 20;
      this.limit = 20;
      this.offset = 0;
      this.listParams.offset = 0;
      this.listParams.statusIds = ["1", "2", "3", "5", "6"];
      this.listParams.createdAtMin = undefined;
      this.listParams.createdAtMax = undefined;
      this.listParams.serialNumber = "";
      this.listParams.office_id = undefined;

      this.listParams.typeIds = undefined;
      this.listParams.companyId = undefined;
      this.listParams.ordersIds = undefined;
      this.listParams.reverseOrdersIds = undefined;
      this.listParams.parentOrdersIds = undefined;
      this.listParams.childOrdersIds = undefined;
      this.listParams.devicesIds = undefined;
      this.listParams.updatedAtMin = undefined;
      this.listParams.updatedAtMax = undefined;
      this.listParams.employee_id = undefined;
      this.listParams.priority = null;
      this.listParams.visibility = undefined;

      localStorage.setItem(
        "orders-list-params",
        JSON.stringify(this.listParams)
      );

      this.isAuthorIncluded = false;
      this.selectedAuthor = undefined;
      this.employeeSearchAuthor = "";
      this.isExecutorIncluded = false;
      this.selectedExecutor = undefined;
      this.employeeSearchExecutor = "";
      this.isViewerIncluded = false;
      this.selectedViewer = undefined;
      this.employeeSearchViewer = "";
      this.priority = null;
      this.isInternalIncluded = false;
      this.isExternalIncluded = false;

      this.checkStatusId();
      this.getOrdersListWithParams();
    },

    fillUIParams(params) {
      this.statusIdForSelect = "all";
      if (params.statusIds) {
        if (params.statusIds.length > 1) {
          this.statusIdForSelect = "opened";
        } else {
          switch (params.statusIds[0]) {
            case "1":
              this.statusIdForSelect = "in_progress";
              break;
            case "2":
              this.statusIdForSelect = "request_is_created";
              break;
            case "3":
              this.statusIdForSelect = "in_work";
              break;
            case "4":
              this.statusIdForSelect = "is_closed";
              break;
            case "5":
              this.statusIdForSelect = "is_agreement";
              break;
            case "6":
              this.statusIdForSelect = "is_completed";
              break;
          }
        }
      }
      if (params.deviceName) {
        this.serialNumber = params.deviceName;
      }
      /* if (params.performerId || params.authorId) {
        this.listParams.selectedType = params.performerId
          ? "Executor"
          : "Author";
        const foundEmployee = this.sortedEmployeeByInput.find(
          employee => employee.id === params.performerId || params.authorId
        );
        if (foundEmployee) {
          this.selectEmployee(foundEmployee);
        }
      } */
      if (params.authorId) {
        this.isAuthorIncluded = true;
        this.selectedAuthor = params.authorId;
        const foundEmployee = this.employees.find(
          empl => empl.id === params.authorId
        );
        this.employeeSearchAuthor = foundEmployee.value;
      }
      if (params.performerId) {
        this.isExecutorIncluded = true;
        this.selectedExecutor = params.performerId;
        const foundEmployee = this.employees.find(
          empl => empl.id === params.performerId
        );
        this.employeeSearchExecutor = foundEmployee.value;
      }
      if (params.viewerId) {
        this.isViewerIncluded = true;
        this.selectedViewer = params.viewerId;
        const foundEmployee = this.employees.find(
          empl => empl.id === params.viewerId
        );
        this.employeeSearchViewer = foundEmployee.value;
      }
      if (params.deviceOfficeId) {
        const foundOffice = this.officeList.find(
          office => office.id === params.deviceOfficeId
        );
        if (foundOffice) {
          this.selectOffice(foundOffice);
        }
      }
      if (params.createdAtMax) {
        this.created_at_max_not_format = params.createdAtMax.substr(0, 10);
      }
      if (params.createdAtMin) {
        this.created_at_min_not_format = params.createdAtMin.substr(0.1);
      }
      if (params.visibility === "internal") {
        this.isInternalIncluded = true;
      }
      if (params.visibility === "external") {
        this.isExternalIncluded = true;
      }
      if (params.priority) {
        this.priority = params.priority[0];
      }
    },

    getOrdersVisibilityFilterValue() {
      if (
        (this.isInternalIncluded && this.isExternalIncluded) ||
        (!this.isInternalIncluded && !this.isExternalIncluded)
      ) {
        return undefined;
      }
      if (this.isInternalIncluded) {
        return "internal";
      }
      if (this.isExternalIncluded) {
        return "external";
      }
    },

    // Функция для поиска задач с условием фильтрации
    async getOrdersListWithParams(dynamicParams) {
      Date.prototype._addHours = function(h) {
        this.setHours(this.getHours() + h);
        return this;
      };

      Date.prototype._addMinutes = function(m) {
        this.setMinutes(this.getMinutes() + m);
        return this;
      };

      let maxDate = this.created_at_max_not_format
        ? new Date(this.created_at_max_not_format)._addHours(23)._addMinutes(59)
        : undefined;

      this.listParams.created_at_min = this.created_at_min_not_format
        ? new Date(this.created_at_min_not_format)
        : undefined;
      this.listParams.created_at_max = maxDate;

      this.listParams.serialNumber = this.serialNumber;

      this.spinner = true;
      if (this.ordersApi.api && this.ordersApi.api.listOrders) {
        if (this.firstStart === true) {
          await this.getCompanyUsers();
          this.offset = 0;
          this.listParams.offset = 0;
          this.firstStart = false;
        }

        try {
          const params = {
            limit: this.limit,
            offset: this.offset,
            order: this.sortOrder || "DESC",
            sort: this.sortType || "updatedAt",
            statusIds: this.listParams.statusIds,
            createdAtMin: this.listParams.created_at_min,
            createdAtMax: this.listParams.created_at_max,
            deviceName: this.listParams.serialNumber,
            deviceOfficeId: this.listParams.office_id,
            authorId: this.isAuthorIncluded ? this.selectedAuthor : undefined,
            performerId: this.isExecutorIncluded
              ? this.selectedExecutor
              : undefined,
            viewerId: this.isViewerIncluded ? this.selectedViewer : undefined,
            priority:
              parseInt(this.priority) > -1
                ? [this.priority.toString()]
                : undefined,
            visibility: this.getOrdersVisibilityFilterValue()
          };
          const { items, count } = await this.ordersApi.api.listOrders(
            dynamicParams || params
          );
          this.count = count;
          this.ordersCount = count;
          this.orders = this.needAppendList
            ? [...this.orders, ...items]
            : items;
          this.orders = this.uniqBy(this.orders, "id");
          this.orders.forEach(order => (order.devices = []));
          this.offset = this.orders.length;
          const ordersListIds = this.orders.map(order => order.id);
          this.spinner = false;
          let devicesListIds = [];
          this.references = await RequestManager().ordersApi.getOrdersReferences(
            ordersListIds,
            ["devices"]
          );
          this.references.forEach(refer => {
            if (refer.devicesIds && refer.devicesIds.length) {
              devicesListIds = devicesListIds.concat(refer.devicesIds);
            }
            const foundOrder = this.orders.find(order => order.id === refer.id);
            if (foundOrder) {
              foundOrder.references = refer;
            }
          });
          devicesListIds = devicesListIds.filter(onlyUnique);
          const devicesList = await RequestManager().deviceApi.listDevices({
            ids: devicesListIds
          });
          devicesList.items.forEach(device => {
            const foundReference = this.references.find(refer =>
              refer.devicesIds.includes(device.id)
            );
            if (foundReference) {
              const foundOrder = this.orders.find(
                order => order.id === foundReference.id
              );
              if (foundOrder) {
                foundOrder.devices.push(device);
              }
            }
          });
          localStorage.setItem(
            "orders-list-params",
            JSON.stringify(dynamicParams || params)
          );
        } finally {
          /* if (this.orders.length) {
            this.isIntroPassed = true;
            localStorage.setItem("isIntroPassed", "true");
          } */
          this.spinner = false;
          if (dynamicParams) {
            this.fillUIParams(dynamicParams);
          }
        }
      } else {
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        //  alert("Страница задач не успела загрузиться! Зайдите , пожалуйста, в задачи заново чедрез 5 секунд!");
        //  this.$router.push("/main");
      }
    },

    //********************************************** */,

    getFullName(employee) {
      let {
        first_name,
        last_name,
        email,
        firstName,
        lastName,
        companyName,
        id
      } = employee;
      let result = email;
      first_name = first_name || firstName || "";
      last_name = last_name || lastName || "";

      if ((first_name || last_name) && companyName && companyName !== " ") {
        result = `${companyName}${" "}${"-"}${" "}${first_name} ${last_name}`;
      } else {
        result = ` ${result}`;
      }

      return { id: id, value: result };

      //  return user.getFullName(employee);
    },

    async getRootCompanies() {
      const { items } = await this.companyApi.api.listCompanies({
        crmManagerId: this.currentUserId,
        limit: 100000000
      });
      this.allCompanies = [...[this.mainCompanyInfo], ...items];
      const companyIds = this.allCompanies.map(cmp => parseInt(cmp.id));
      this.allOffices = [];
      this.allOffices = await RequestManager().officeApi.listOffice({
        companyIds: companyIds,
        limit: 1000
      });
      this.allOffices = [...this.allOffices.items];
      this.allOffices.sort((a, b) => a.companyId - b.companyId);

      // const { items } = await this.companyApi.api.listCompanies({
      //   crmManagerId: this.currentUserId,
      //   limit: 100000000
      // });
      // this.allCompanies = [...[this.mainCompanyInfo], ...items];
      // const companyIds = this.allCompanies.map(cmp => parseInt(cmp.id));
      // this.allOffices = [];
      // const ourOffices = await RequestManager().officeApi.listOffice({
      //   companyIds: companyIds,
      //   limit: 1000
      // });
      // this.allOffices = [...this.allOffices, ...ourOffices.items];
      // this.allOffices = this.uniqBy(this.allOffices, "id");
      // RequestManager()
      //   .officeApi.listOffice({
      //     companyIds: companyIds,
      //     limit: 1000
      //   })
      //   .then(response => {
      //     if (response.count > 0) {
      //       this.allOffices = [...this.allOffices, ...response.items];
      //       this.allOffices = this.uniqBy(this.allOffices, "id");
      //       this.allOffices.sort((a, b) => (a.companyId - b.companyId));
      //     }
      //   });
    },

    async getCompanyUsers() {
      try {
        await this.getRootCompanies();
        const allCompaniesIds = this.allCompanies.map(comp =>
          parseInt(comp.id)
        );
        this.isEmployeesListLoading = true;
        /* const { items } = await RequestManager().userApi.listUsers({
          companyId: this.currentUser.company,
          limit: 100000
        }); */
        const { results } = await RequestManager().getUsersList();
        this.employees = results.filter(res =>
          allCompaniesIds.includes(res.company ? res.company : -1)
        );
        this.isEmployeesListLoading = false;
        // this.employees = items;
      } catch (e) {
        throw new Error(e);
      } finally {
        this.employees = this.employees.map(item => {
          // console.log("item",item);
          let company = this.allCompanies.find(
            company1 => +item.company == +company1?.id
          );
          if (company) {
            item.companyName = company.brand ? company.brand : "";
          }
          return item;
        });

        // в строчке - объект {id: id , value: value} , где value - компания + имя + отчество
        this.employees = this.employees.map(item => {
          return this.getFullName(item);
        });
        this.employees.unshift({
          id: this.crmManagerId,
          value: "Персональный менеджер"
        });
      }

      // Сортировка
      /* if (this.employees && this.employees.length > 1)
        this.employees
          .sort((a, b) => a.lastName.localeCompare(b.lastName))
          .sort(
            (a, b) =>
              Number(a.id === this.crmManagerId) -
              Number(b.id === this.crmManagerId)
          ); */
    },

    async getOrdersList() {
      try {
        this.spinner = true;
        const { items, count } = await this.ordersApi.api.listOrders({
          limit: this.limit,
          offset: this.offset
        });
        this.count = count;
        this.ordersCount = count;
        this.orders = [...this.orders, ...items];
        this.orders = this.uniqBy(this.orders, "id");
      } finally {
        this.spinner = false;
      }
    },

    loadAllOrders() {
      this.limit = this.ordersCount;
      this.offset = 0;
      this.getOrdersListWithParams();
    },

    incrementOrderNewMessagesCount(orderIdString) {
      const foundOrder = this.orders.find(item => item.id === orderIdString);
      if (foundOrder) {
        foundOrder.chatNewMessagesCount += 1;
      }
    },

    setColumnsVisibilityValue(newColumnsVisibility) {
      this.columnsVisibility = Object.assign({}, newColumnsVisibility);
      localStorage.setItem(
        `${STORAGE_PREFIX}cols_visibility`,
        JSON.stringify(this.columnsVisibility)
      );
      this.columnsVisibilityKey += 1;
    },

    getSavedColumnsVisibilityOrDefault() {
      const columnsVisibilitySrc = localStorage.getItem(
        `${STORAGE_PREFIX}cols_visibility`
      );
      this.columnsVisibility = columnsVisibilitySrc
        ? Object.assign({}, JSON.parse(columnsVisibilitySrc))
        : Object.assign({}, DEFAULT_COLUMNS_CONFIG);
      if (this.columnsVisibility.id) {
        delete this.columnsVisibility.id;
      }
    }
  },

  async created() {
    this.getSavedColumnsVisibilityOrDefault();
    this.isIntroPassed = localStorage.getItem("isIntroPassed");
    this.currentUserId = parseInt(
      JSON.parse(window.localStorage.getItem("currentUser")).id
    );
    this.currentUser = JSON.parse(window.localStorage.getItem("currentUser"));
    this.mainCompanyInfo = JSON.parse(
      window.localStorage.getItem("mainCompanyInfo")
    );
    this.firstStart = true;
    const isOrdersFiltersVisibleSrc = localStorage.getItem(
      "isOrdersFiltersVisible"
    );
    this.isOrdersFiltersVisible = isOrdersFiltersVisibleSrc
      ? JSON.parse(isOrdersFiltersVisibleSrc)
      : true;
    this.listView = localStorage.getItem("ordersListView") || "grid";

    try {
      let paramsTemp = localStorage.getItem("orders-list-params");
      if (paramsTemp.length > 0) {
        paramsTemp = JSON.parse(paramsTemp);

        if (this?.listParams) {
          this.listParams = paramsTemp;
          this.sortType = this.listParams.sort;
          this.sortOrder = this.listParams.order;
        }
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      this.listParams.limit = this.listParams.limit || 20;
      await this.getOrdersListWithParams(this.listParams);
    }
  },

  mounted() {
    if (this.isIntroPassed === "false") {
      doPoseTip(".tip-anchor", "bottom");
    }
    eventBus.$on("order_new-message", this.incrementOrderNewMessagesCount);
  }
};
</script>

<style scoped lang="scss">
.order-create-btn {
  position: absolute;
  bottom: 45px;
  right: 36px;
  width: 80px;
  height: 80px;
  background: #006b90;
  border-radius: 50%;
  font-size: 50px;
  border: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.order-create-btn p {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  width: 50px;
  height: 50px;
  background-image: url(/resource/img/plus-big.7ad758c8.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.order-create-btn:hover {
  background: #0091c3;
}

.clear {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.scroll-container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  flex: 1;

  .b-overlay-wrap {
    height: 100% !important;
  }

  .__vuescroll {
    height: 100% !important;
    width: 100% !important;
  }
}

.showMore {
  background-color: #357af6;
  margin: 15px 0 30px;
  display: flex;
  justify-content: center;
}

.grid-filter {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));

  label {
    margin-bottom: 0;
  }
}

.spinner {
  display: flex;
  justify-content: center;
}

.grid {
  margin-right: 15px;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}

.optgroup {
  padding: 5px 10px;
  position: absolute;
  min-width: 450px;
  width: 100%;
  max-height: 320px;
  border: 1px solid #ced4da;
  z-index: 9;
  background-color: white;
  overflow: auto;

  &_item {
    cursor: pointer;

    &:hover {
      background-color: #fafafc;
    }
  }
}

.offices {
  background-color: var(--main-card-color);

  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.employees {
  background-color: var(--main-card-color);

  .optgroup_item:hover {
    background-color: rgba(184, 218, 255, 0.5);
  }
}

.form-control {
  background-color: var(--main-card-color);
  &:focus {
    background-color: var(--main-card-color);
    color: var(--main-text-color);
  }
}

.custom-select {
  background-color: var(--main-card-color);
  color: #495057;
  &:focus {
    background-color: var(--main-card-color);
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: var(--filter-for-date);
}

.pseudo-link {
  cursor: pointer;
  font-weight: 600;
}

.item-label {
  min-width: 130px !important;
}

.form-control[disabled] {
  background-color: #fafafc;
}

.filtered-dropdown__items {
  max-height: 350px !important;
  overflow-y: auto !important;
  width: 250px;
}

.filter-datalist {
  max-height: 350px !important;
  overflow-y: auto !important;
}

.flex-1 {
  flex: 1;
}

label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
input[type="date"] {
  max-width: 132px;
}
.optgroup_item {
  min-width: 200px;
}

.yellow {
  color: #fbbd3b;
  font-weight: 600;
}
.blue {
  color: #2378f9;
  font-weight: 600;
}
.orange {
  color: #fb823b;
  font-weight: 600;
}
.purple {
  color: #7c3bfb;
  font-weight: 600;
}
.green {
  color: #2dc44f;
  font-weight: 600;
}
.rifle-green {
  color: #116e25;
  font-weight: 600;
}

.text-sm {
  font-size: 0.9em !important;
}

#grid-view {
  width: 16px;
  height: 16px;
  margin: 5px;
  cursor: pointer;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.91 1.455c-.804 0-1.456.65-1.456 1.454v1.455c0 .804.652 1.455 1.455 1.455h1.455c.803 0 1.454-.651 1.454-1.455V2.91c0-.803-.65-1.454-1.454-1.454H2.909ZM0 2.909A2.91 2.91 0 0 1 2.91 0h1.454a2.91 2.91 0 0 1 2.909 2.91v1.454a2.91 2.91 0 0 1-2.91 2.91H2.91A2.91 2.91 0 0 1 0 4.364V2.91Zm11.636-1.454c-.803 0-1.454.65-1.454 1.454v1.455c0 .804.65 1.455 1.454 1.455h1.455c.803 0 1.455-.651 1.455-1.455V2.91c0-.803-.652-1.454-1.455-1.454h-1.455ZM8.727 2.909A2.91 2.91 0 0 1 11.637 0h1.454A2.91 2.91 0 0 1 16 2.91v1.454a2.91 2.91 0 0 1-2.91 2.91h-1.454a2.91 2.91 0 0 1-2.909-2.91V2.91ZM2.91 10.181c-.803 0-1.455.651-1.455 1.455v1.455c0 .803.652 1.455 1.455 1.455h1.455c.803 0 1.454-.652 1.454-1.455v-1.455c0-.804-.65-1.455-1.454-1.455H2.909ZM0 11.636a2.91 2.91 0 0 1 2.91-2.91h1.454a2.91 2.91 0 0 1 2.909 2.91v1.455A2.91 2.91 0 0 1 4.363 16H2.91A2.91 2.91 0 0 1 0 13.09v-1.454Zm11.636-1.455c-.803 0-1.454.651-1.454 1.455v1.455c0 .803.65 1.455 1.454 1.455h1.455c.803 0 1.455-.652 1.455-1.455v-1.455c0-.804-.652-1.455-1.455-1.455h-1.455Zm-2.909 1.455a2.91 2.91 0 0 1 2.91-2.91h1.454A2.91 2.91 0 0 1 16 11.636v1.455A2.91 2.91 0 0 1 13.09 16h-1.454a2.91 2.91 0 0 1-2.909-2.91v-1.454Z' fill='%23333'/%3E%3C/svg%3E");
}
#table-view {
  width: 16px;
  height: 16px;
  margin: 5px;
  cursor: pointer;
  opacity: 0.5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.91 1.455c-.804 0-1.456.65-1.456 1.454v1.455c0 .804.652 1.455 1.455 1.455H13c.803 0 1.455-.651 1.455-1.455V2.91c0-.803-.652-1.454-1.455-1.454H2.91ZM0 2.909A2.91 2.91 0 0 1 2.91 0H13a2.91 2.91 0 0 1 2.91 2.91v1.454A2.91 2.91 0 0 1 13 7.274H2.91A2.91 2.91 0 0 1 0 4.364V2.91Zm2.91 7.272c-.804 0-1.456.651-1.456 1.455v1.455c0 .803.652 1.455 1.455 1.455H13c.803 0 1.455-.652 1.455-1.455v-1.455c0-.804-.652-1.455-1.455-1.455H2.91ZM0 11.636a2.91 2.91 0 0 1 2.91-2.91H13a2.91 2.91 0 0 1 2.91 2.91v1.455A2.91 2.91 0 0 1 13 16H2.91A2.91 2.91 0 0 1 0 13.09v-1.454Z' fill='%23333'/%3E%3C/svg%3E");
}
#grid-view:hover,
#table-view:hover,
#grid-view.active,
#table-view.active {
  opacity: 1;
}

.columns-visibility-settings {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.pseudo-link {
  cursor: pointer;
}

.my-request {
  overflow-x: auto;
}

.__view,
.table {
  max-width: 100%;
  max-height: 100%;
}

/deep/ th {
  white-space: nowrap;
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}
td,
td div,
td span {
  word-wrap: break-word;
  max-width: 240px;
}

/deep/ .table tbody .custom-checkbox {
  z-index: 0;
}

/deep/ .__bar-is-vertical {
  z-index: 10 !important;
}

/deep/ .__bar-is-vertical {
  z-index: 10 !important;
}

.filters-hider-icon {
  opacity: 0.65;
  cursor: pointer;
}
.filters-hider-icon:hover {
  opacity: 1;
}
</style>
